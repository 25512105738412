<template>
    <div>
        <hooper
            :centerMode="centerMode" 
            :wheelControl="wheelControl"
            :mouseDrag="mouseDrag"
            :autoPlay="autoPlay" 
            :playSpeed="playSpeed"
            :itemsToShow="itemsToShow"
            :infiniteScroll="infiniteScroll"
        >
            <slide 
                v-for="(item, i) in dtImg" :key="i" 
            >
               
                <progressive-img
                    :src="item.image ? item.image : item"
                    :blur=2
                    style="max-width:100%!important;"
                    v-if="lang == 'id'"
                />

                <progressive-img
                    :src="item.image_eng ? item.image_eng : item.image"
                    :blur=2
                    style="max-width:100%!important;"
                    v-else
                />

            </slide>
            
            <hooper-navigation slot="hooper-addons" v-if="nav"></hooper-navigation>
            <hooper-pagination slot="hooper-addons" v-if="nav"></hooper-pagination>
            <!-- <hooper-progress slot="hooper-addons" ></hooper-progress> -->
        </hooper>
       
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {
    Hooper,
    Slide,
    Navigation as HooperNavigation,
    Pagination as HooperPagination,
    Progress as HooperProgress
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    components: {
        Hooper,
        Slide,
        HooperNavigation,
        HooperPagination,
        HooperProgress
    },
    data() {
        return {
            imgPreload:require(`@/assets/img/loading1.gif`)
        }
    },
    props:{
        dtImg: {
            type: Array,
        },
        nav: {
            type: Boolean,
            default: true
        },
        centerMode: {
            type: Boolean,
            default: false
        },
        mouseDrag: {
            type: Boolean,
            default: false
        },
        wheelControl:{
            type: Boolean,
            default: false
        },
        autoPlay:{
            type: Boolean,
            default: false
        },
        playSpeed:{
            type: Number,
            default: 6000
        },
        itemsToShow:{
            type: Number,
            default: 1
        },
        infiniteScroll:{
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState({
            lang: state => state.home.lang ? state.home.lang :'id'
        }),
    },
}

</script>

<style>
    @layer components {
        .hooper{
            @apply h-full max-h-banner overflow-hidden;
        }

        .hooper-prev, .hooper-next{
            @apply bg-white p-2 rounded-sm bg-opacity-50
        }

        .hooper-next{
            @apply right-10
        }

        .hooper-prev{
            @apply left-10
        }

        .hooper-progress{
            @apply   bg-grays-400 w-72;
           
            left:200px;
        }

        .hooper-progress-inner{
            @apply bg-white;
        }

        .progressive-image{
            max-width:100% !important;
        }

       
    }
</style>